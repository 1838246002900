import { Routes, Route, Outlet } from 'react-router-dom'
//import Index from './index.js'

function App() {
  return (
    <div>
      <Routes>
        <Route path='*' element={<div></div>} />     
     </Routes> 
     <Outlet />   
    </div>
  );
}

export default App;
